// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  Razorpay_KeyId: "rzp_test_wXw4aRZMqCsiAq",
  apiBaseUrl:
    'http://ripple-metering.centralindia.cloudapp.azure.com/api/gateway/',

  ripple_projects_baseUrl: 'https://ripple-projects.azurewebsites.net/v1/',
  ripple_metering_baseurl: 'https://ripple-metering.azurewebsites.net/v1/',
  ripple_core_baseUrl: 'https://ripple-core-app.azurewebsites.net/v1/',
  ripple_billing_baseUrl: 'https://ripple-billing.azurewebsites.net/v1/',
  ripple_wallet_baseUrl: 'https://ripple-wallet.azurewebsites.net/v1/',
  ripple_agent_admin_feedBack_baseUrl: 'https://ripple-core-app.azurewebsites.net/v1/',
  ripple_super_admin_baseUrl: 'https://ripple-core-app.azurewebsites.net/v1/',



  production: false,
  defaultauth: 'fakebackend',
  firebaseConfig: {
    apiKey: "AIzaSyDTWaMGbVE8YSDZsBLu5HRwkltUfHVXX6Y",
    authDomain: "ripple-metering.firebaseapp.com",
    projectId: "ripple-metering",
    storageBucket: "ripple-metering.appspot.com",
    messagingSenderId: "122652766285",
    appId: "1:122652766285:web:db3f8a001edd7024082c04",
    measurementId: "G-V5QL4M3J0N"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
