import { Pipe, PipeTransform } from '@angular/core';
import { Helper } from '../../helpers/helper';

@Pipe({
  name: 'appFilter',
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], searchText: string, selectedDate: string): any[] {
    if (!items) return [];

    // Filter by searchText
    let filteredItems = items;
    if (searchText) {
      searchText = searchText.toLowerCase();
      filteredItems = filteredItems.filter((it) => {
        return (
          it.name.toLowerCase().includes(searchText) ||
          it.description.toLowerCase().includes(searchText) ||
          it?.residentialUnit?.toLowerCase().includes(searchText)
        );
      });
    }

    // Filter by selectedDate
    if (selectedDate) {
      filteredItems = filteredItems.filter((it) => {
        console.log(
          Helper.formatDate(it.dateTime),
          Helper.formatDate(selectedDate)
        );

        return (
          Helper.formatDate(it.dateTime) === Helper.formatDate(selectedDate)
        );
      });
    }

    return filteredItems;
  }
}
